"use strict";

// Avoid `console` errors in browsers that lack a console.
(function() {
	var method;
	var noop = function () {};
	var methods = [
		'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
		'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
		'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
		'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'
	];
	var length = methods.length;
	var console = (window.console = window.console || {});
	
	while (length--) {
		method = methods[length];
		
		// Only stub undefined methods.
		if (!console[method]) {
			console[method] = noop;
		}
	}
}());

//console.log('main.js loaded');

// Add Plugins here ----------------------------------------------------------------------------------------
import 'simplelightbox/dist/simple-lightbox.jquery.js';

// Theme functions & Global Scope Vars ---------------------------------------------------------------------
const $ = jQuery;

// Debounce Function...
function debounce(func, wait) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			func.apply(context, args);
		};
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
	};
}

// Toggle mobile menu
function toggleMobileMenu() {
	if (window.innerWidth < 901) {
		$('.show-menu').off('click').on('click', function () {
			$('.primary-nav-menu, .mobile-nav-buttons, .lines, .show-menu').toggleClass('show');
		});
	}
}

// Add class 'focus' to .menu-item-has-children on hover on screen sizes larger than 900px
function hoverSubMenu() {
	$('.menu-item-has-children').off('mouseenter mouseleave').on('mouseenter mouseleave', function(e) {
		if (window.innerWidth > 900) {
			$(this).toggleClass('focus', e.type === 'mouseenter');
			$(this).find('.sub-menu').toggleClass('open', e.type === 'mouseenter');
		}
	});
}

// Toggle open sub-menu on click
function toggleSubMenu() {
	$('.menu-item-has-children > a').off('click').on('click', function(e) {
		if (window.innerWidth < 901) {
			e.preventDefault();
			$(this).next('.sub-menu').toggleClass('open');
			$(this).parent().toggleClass('open');
		}
	});
}

// on mobile get the parent link URL and text and add it to the submenu at the beginning and wrap in a <li> tag with the class 'menu-item'
function mobileSubMenu() {
	if (window.innerWidth < 901) {
		$('.menu-item-has-children').each(function() {
			var parentLink = $(this).children('a').clone();
			var parentLinkText = parentLink.text();
			var parentLinkURL = parentLink.attr('href');
			var parentLinkLi = '<li class="menu-item"><a href="'+parentLinkURL+'">'+parentLinkText+'</a></li>';
			$(this).find('.sub-menu').prepend(parentLinkLi);
		});
	}
}

// Add target _blank to external links & PDF's.
function add_target_blank_to_external_links_and_pdfs() {
	$('a[href$=".pdf"]').addClass('download').attr('target', '_blank');
	$('a[href^="http://"], a[href^="https://"]').not('a[href*="'+location.hostname+'"]').attr('target','_blank');
}

// Initialize all functions
function initializeFunctions() {
	toggleMobileMenu();
	toggleSubMenu();
	hoverSubMenu();
	add_target_blank_to_external_links_and_pdfs();
}

// Theme jQuery here ---------------------------------------------------------------------------------------
$(document).ready(function($) {
	initializeFunctions();
	mobileSubMenu();
	
	// Simple Lightbox
	if ($('.gallery').length) {
		new SimpleLightbox('.gallery a', {
			nav: true,
			captions: true,
			captionDelay: 0
		});
	}
});

$(window).on('resize', debounce(function() {
	initializeFunctions();
}, 250));